<template>
  <transition name="fade-in"
              v-if="valid">
    <div class="retail-notification">
      <InfoSvg />
      <span>{{ $store.getters.translate(title) }}</span>
    </div>
  </transition>
</template>

<script>
import InfoSvg from '@/components/Retail/Svgs/InfoSvg';

export default {
  name: 'Notification',
  components: { InfoSvg },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    valid: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.retail-notification {
  align-items: center;
  background: rgba(220, 62, 62, 0.9);
  border-radius: 82px;
  bottom: 20px;
  display: flex;
  font-size: 18px;
  height: 60px;
  justify-content: center;
  left: 16px;
  position: absolute;
  right: 16px;
  span {
    padding-left: 8px;
  }
}
</style>
