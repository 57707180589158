var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"retail-header",class:{'down': _vm.curtainDown}},[_c('div',{staticClass:"header-curtain"},[_c('div',{staticClass:"curtain-info",style:({left: _vm.toLeft})},[_c('LotterySvg',{staticClass:"lottery-icon",class:{ 'active': _vm.showIcon },attrs:{"inner-full-color":_vm.innerFullColor,"blur":_vm.blur,"outer-full-color":false}}),_c('h3',{class:{'active': _vm.showTitle, 'active-subtitle': _vm.curtainSubtitleActive},style:({left: _vm.toLeftTitle })},[_vm._v(_vm._s(_vm.getName))])],1),_c('div',{staticClass:"header-next-draw-time",class:{'active': _vm.nextDrawActive
         && _vm.$store.getters.activeCounter
         && _vm.$store.getters.appAvailable
         && _vm.$store.getters.isVisualizationDrawEnabled
         && !_vm.connectionError
         && _vm.headerWholeUp
         && !_vm.disabledActiveCounter}},[_c('div',{staticClass:"info-titles"},[_c('span',[_vm._v(_vm._s(_vm.$store.getters.translate('nextDraw')))]),_c('span',[_vm._v(_vm._s(_vm.$store.getters.translate('startingIn')))])]),_c('div',{staticClass:"info-time"},[_c('RemainingTime',{attrs:{"in-header":""}})],1)]),_c('div',{staticClass:"header-next-draw-time draw-id",class:{'active': _vm.drawIdActive
         && _vm.$store.getters.appAvailable
         && _vm.$store.getters.drawnResults.length}},[_c('div',{staticClass:"info-titles"},[_c('span',[_vm._v(_vm._s(_vm.$store.getters.translate('headerNumberDescriptionUp')))]),_c('span',[_vm._v(_vm._s(_vm.$store.getters.translate('headerNumberDescriptionDown')))])]),_c('div',{staticClass:"info-time"},[_vm._v(" "+_vm._s(_vm.drawIdActive ? _vm.retailDrawEvent.lotteryDisplayId : '')+" ")])])]),_c('div',{staticClass:"subtitle",class:{active: _vm.curtainSubtitleActive}},[_vm._v(" "+_vm._s(_vm.$store.getters.translate(_vm.curtainSubtitle))+" ")]),_c('div',{staticClass:"header-loader"},[(_vm.loaderActive && !_vm.$store.getters.fetchEventsError)?_c('Loaders',{attrs:{"is-results-data-valid":_vm.$store.getters.isResultsDataValid,"start-time":_vm.drawTime}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }