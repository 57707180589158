<template>
  <article class="loaders">
    <p class="loaders-titles"
       v-if="$store.getters.layout !== 'RetailLayout'">
      <span>{{getTitle}}</span>
      <span v-show="type === 'counter'">{{ remainingTime }}</span>
    </p>
    <div class="loaders-wrapper"
         :style="{height: $store.getters.layout !== 'RetailLayout' ? '2px' : '5px'}">
      <div class="loader"
           :class="[type === 'counter' ? 'bar' : 'waiting', {'warning': warningColor}]"
           v-show="startOfRemainingTime"
           :style="{animationDuration: `${startOfRemainingTime}s`}">
      </div>
    </div>
  </article>
</template>

<script>
import round from 'lodash/round';
import { formatDate } from '@/utility';
import EventBus from '@/store/EventBus';

export default {
  name: 'Loaders',
  data() {
    return {
      remainingTime: null,
      startOfRemainingTime: null,
      interval: null,
      counterLoader: 100,
      type: 'counter',
      loaderName: 'loader',
      waitingAnimationDuration: 1.5,
      timeout: null,
      warningColor: false,
    };
  },
  props: {
    startTime: {
      type: Number,
      required: true,
    },
    isResultsDataValid: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getTitle() {
      let title = null;
      const time = this.isResultsDataValid ? formatDate(this.startTime, 'HH:mm', this.$store.getters.timeZone) : '';
      title = this.type === 'counter'
        ? `${this.$store.getters.translate('drawFrom')} ${time}${this.$store.getters.translate('h')} ${this.$store.getters.translate('startingIn')} `
        : `${this.$store.getters.translate('waitingForDrawnFrom')} ${time}${time ? this.$store.getters.translate('h') : ''} ...`;
      return title;
    },
  },
  methods: {
    calcRemainingTime() {
      let concatTime = '';
      const tempTime = new Date().getTime();
      const remainingTime = this.startTime - tempTime;
      const leftTime = remainingTime / 1000;
      this.warningColor = leftTime > 1 && leftTime <= 15;
      if (leftTime > 1) {
        let hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        if (hours > 0) {
          hours = hours < 10 ? `0${hours}` : hours;
          concatTime = `${concatTime + hours}:`;
        }
        let minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        concatTime = `${concatTime + minutes}:`;
        let seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
        seconds = seconds < 10 ? `0${seconds}` : seconds;
        concatTime = `${concatTime + seconds}`;
        if (!this.startOfRemainingTime) {
          this.startOfRemainingTime = round(remainingTime / 1000);
        }
        this.remainingTime = concatTime;
        this.callRemainingTime();
      } else {
        this.type = 'waiting';
        this.remainingTime = '';
        this.startOfRemainingTime = this.waitingAnimationDuration;
        EventBus.$emit('new-event-starting');
      }
    },
    callRemainingTime() {
      this.timeout = setTimeout(() => {
        this.calcRemainingTime();
      }, 1000);
    },
  },
  mounted() {
    this.calcRemainingTime();
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
};
</script>

<style lang="scss" scoped>
 .loaders-wrapper {
   background: #3F4144;
   overflow: hidden;
   position: relative;
   .loader {
     background-color: #fff;
     height: 100%;
     transition: background-color 0.5ms linear;
     &.warning {
       background-color: rgba(255, 35, 35, 0.5);
       transition: background-color 1s linear !important;
     }
     &.bar {
       animation-name: bar;
       animation-timing-function: linear;
     }
     &.waiting {
       animation-iteration-count: infinite;
       animation-name: waiting;
       animation-timing-function: linear;
     }
   }
 }
 @keyframes bar {
   0% {
     width: 100%;
   }
   100% {
     width: 0;
   }
 }
 @keyframes waiting {
   0% {
     transform: translateX(0%);
     width: 0;
   }
   15% {
     transform: translateX(40%);
     width: 5%;
   }
   30% {
     transform: translateX(60%);
     width: 15%;
   }
   60% {
     transform: translateX(100%);
     width: 50%;
   }
   100% {
     transform: translateX(140%);
     width: 70%;
   }
 }
</style>
