<template>
  <div class="retail-layout">
    <Header />
    <component v-if="$store.getters.appAvailable && !$store.getters.fetchEventsError"
               :is="selectLayout"></component>
    <Notification title="reconnecting"
                  :valid="$store.getters.connectionError" />
  </div>
</template>

<script>
import Header from '@/components/Retail/Header';
import EventBus from '@/store/EventBus';
import Notification from '@/components/Retail/Notification';
import { first } from 'lodash';
import { workerPostMessage } from '@/utility/workerMessage';

export default {
  name: 'RetailLayout',
  components: {
    Notification,
    Header,
    CommingDrawsWrapper: () => import('../components/Retail/CommingDrawsWrapper'),
    ComingOtherWrapper: () => import('../components/Retail/ComingOtherWrapper'),
  },
  data() {
    return {
      selectLayout: '',
    };
  },
  methods: {
    async startUpdateRetail(type = null) {
      const isVisualizationDrawEnabled = await this.$store.dispatch('fetchUpcomingEvents', type);
      this.selectLayout = isVisualizationDrawEnabled ? 'CommingDrawsWrapper' : 'ComingOtherWrapper';
      return true;
    },
    checkNoInternetTime() {
      const tempTime = new Date().getTime();
      if (this.$store.getters.drawInProgress) {
        return;
      }
      if (!this.$store.getters.dontHaveInternetTime) {
        this.$store.dispatch('setDontHaveInternetTime', tempTime);
        this.$store.dispatch('setConnectionError', true);
      } else {
        const time = (tempTime - this.$store.getters.dontHaveInternetTime) / 1000;
        if (time > 10) {
          setTimeout(() => {
            this.$store.dispatch('setAppAvailable', false);
          }, 3000);
          EventBus.$emit('curtainDown', 'connectionError');
        }
      }
    },
  },
  async beforeMount() {
    await this.startUpdateRetail();
  },
  mounted() {
    EventBus.$on('fetchOthers', () => {
      this.startUpdateRetail('draws');
      this.startUpdateRetail('others');
    });
    EventBus.$on('checkInternet', (status) => {
      if (!status.internet) {
        this.checkNoInternetTime();
      } else if (this.$store.getters.connectionError) {
        this.$store.dispatch('setConnectionError', false);
        this.$store.dispatch('setDontHaveInternetTime', null);
        this.$store.dispatch('setAppAvailable', true);
        EventBus.$emit('curtainUp');
      }
      if (status.internet
        && (
          (!this.$store.getters.isVisualizationDrawEnabled && status.displayVisualization)
          || (this.$store.getters.isVisualizationDrawEnabled && !status.displayVisualization)
        || this.$store.getters.fetchEventsError)) {
        EventBus.$emit('disabledActiveCounter', true);
        EventBus.$emit('curtainDown');
        setTimeout(() => {
          this.$store.commit('RESET_DRAW_NEXT_EVENT');
          this.$nextTick(async () => {
            // eslint-disable-next-line no-unused-vars
            const draw = await this.startUpdateRetail('draws');
            // eslint-disable-next-line no-unused-vars
            const others = await this.startUpdateRetail('others');
            if (this.$store.getters.fetchEventsError) {
              setTimeout(() => {
                this.$store.dispatch('setAppAvailable', false);
              }, 3000);
              EventBus.$emit('curtainDown', 'connectionError');
              return;
            }
            workerPostMessage('updateEventsTime', {
              drawTime: this.$store.getters.retailNextEvent?.eventStartTimeUTC - 60000 || null,
              otherNextTime: first(this.$store.getters.bettingOffer(0))?.eventStartTimeUTC - 60000,
              checkStartCounter: status.displayVisualization,
            });
            EventBus.$emit('curtainUp');
            this.$store.dispatch('setVisualizationDrawEnabled', status.displayVisualization);
            setTimeout(() => {
              EventBus.$emit('disabledActiveCounter', false);
            }, 4000);
          });
        }, 3000);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.retail-layout {
  background: url("../assets/images/balls.jpg");
  background-size: contain;
  color: rgba(255, 255, 255, .6);
  height: 100vh;
  overflow: hidden;
  position: relative;
}
</style>
