<template>
  <div class="retail-header"
       :class="{'down': curtainDown}">
    <div class="header-curtain">
      <div class="curtain-info"
           :style="{left: toLeft}">
        <LotterySvg class="lottery-icon"
                    :inner-full-color="innerFullColor"
                    :blur="blur"
                    :outer-full-color="false"
                    :class="{ 'active': showIcon }"/>
        <h3 :class="{'active': showTitle, 'active-subtitle': curtainSubtitleActive}"
            :style="{left: toLeftTitle }">{{ getName }}</h3>
      </div>
      <div class="header-next-draw-time"
           :class="{'active': nextDrawActive
           && $store.getters.activeCounter
           && $store.getters.appAvailable
           && $store.getters.isVisualizationDrawEnabled
           && !connectionError
           && headerWholeUp
           && !disabledActiveCounter}">
        <div class="info-titles">
          <span>{{ $store.getters.translate('nextDraw') }}</span>
          <span>{{ $store.getters.translate('startingIn') }}</span>
        </div>
        <div class="info-time">
          <RemainingTime in-header />
        </div>
      </div>
      <div class="header-next-draw-time draw-id"
           :class="{'active': drawIdActive
           && $store.getters.appAvailable
           && $store.getters.drawnResults.length}">
        <div class="info-titles">
          <span>{{ $store.getters.translate('headerNumberDescriptionUp') }}</span>
          <span>{{ $store.getters.translate('headerNumberDescriptionDown') }}</span>
        </div>
        <div class="info-time">
          {{ drawIdActive ? retailDrawEvent.lotteryDisplayId : '' }}
        </div>
      </div>
    </div>
    <div class="subtitle"
         :class="{active: curtainSubtitleActive}">
      {{ $store.getters.translate(curtainSubtitle) }}
    </div>
    <div class="header-loader">
      <Loaders
        v-if="loaderActive && !$store.getters.fetchEventsError"
        :is-results-data-valid="$store.getters.isResultsDataValid"
        :start-time="drawTime"/>
    </div>
  </div>
</template>

<script>
import LotterySvg from '@/components/Retail/Svgs/LotterySvg';
import RemainingTime from '@/components/Retail/RemainingTime';
import EventBus from '@/store/EventBus';
import Loaders from '@/components/Loaders';
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  components: { Loaders, RemainingTime, LotterySvg },
  data() {
    return {
      showTitle: false,
      showIcon: false,
      nextDrawActive: false,
      innerFullColor: false,
      outerFullColor: false,
      blur: false,
      curtainDown: true,
      curtainSubtitleActive: false,
      curtainSubtitle: '',
      disabledActiveCounter: false,
      drawIdActive: false,
      connectionError: false,
      headerWholeUp: false,
    };
  },
  computed: {
    ...mapGetters([
      'retailDrawEvent',
    ]),
    getName() {
      return this.$store.getters.translate('lotteryDraw');
    },
    toLeft() {
      let left = '0';
      if (!this.curtainDown) {
        left = this.$store.getters.screenSize === 1280 ? '-50px' : '-85px';
      }
      return left;
    },
    toLeftTitle() {
      let left = '50%';
      if (!this.curtainDown) {
        left = this.$store.getters.screenSize === 1280 ? '45px' : '62px';
      }
      return left;
    },
    drawInProgress() {
      return this.$store.getters.drawInProgress;
    },
    drawTime() {
      return this.retailDrawEvent?.eventStartTimeUTC - 60000 || 0;
    },
    loaderActive() {
      return !this.curtainDown
        && !this.drawInProgress
        && this.showTitle
        && this.$store.getters.isVisualizationDrawEnabled;
    },
  },
  methods: {
    upCurtain(curtainUpDuration = 7000, toggleText = 1500, blur = false) {
      this.connectionError = false;
      setTimeout(() => {
        this.showIcon = true;
      }, 500);
      this.toggleText(true, toggleText);
      this.changeLogoColor(true, blur);
      if (!this.$store.getters.fetchEventsError) {
        this.handlingCurtain(false, curtainUpDuration);
      }
    },
    changeLogoColor(status, blur = false, duration = 3500) {
      setTimeout(() => {
        this.innerFullColor = true;
        if (blur) {
          setTimeout(() => {
            this.toggleBlur();
          }, 50);
        }
      }, duration);
    },
    toggleBlur() {
      this.blur = true;
      setTimeout(() => {
        this.blur = false;
        this.toggleText(this.drawInProgress, 0);
      }, 2000);
    },
    toggleText(status, duration) {
      setTimeout(() => {
        this.showTitle = this.$store.getters.fetchEventsError ? true : status;
      }, duration);
    },
    toggleNextDraw(status, duration) {
      setTimeout(() => {
        this.nextDrawActive = status;
      }, duration);
    },
    handlingCurtain(status, duration, subtitle = null) {
      setTimeout(() => {
        this.curtainDown = status;
        if (!this.curtainDown) {
          this.toggleCurtainSubtitle(false, null, 1000);
          this.toggleText(false, 1000);
          this.toggleText(true, 2000);
          setTimeout(() => {
            this.headerWholeUp = true;
          }, 2000);
          if (!this.drawInProgress) {
            this.toggleNextDraw(true, 5000);
          }
        } else {
          this.headerWholeUp = false;
          this.toggleText(true, 2000);
          this.toggleCurtainSubtitle(true, subtitle, 2000);
        }
      }, duration);
    },
    toggleCurtainSubtitle(status, title = '', duration = 1000) {
      if (title !== 'connectionError' && this.$store.getters.fetchEventsError) {
        return;
      }
      if (status) {
        this.curtainSubtitle = title;
        setTimeout(() => {
          this.curtainSubtitleActive = true;
        }, duration);
      } else {
        this.curtainSubtitleActive = false;
        setTimeout(() => {
          this.curtainSubtitle = '';
        }, duration);
      }
    },
  },
  mounted() {
    this.upCurtain(7000, 1500, true);
    EventBus.$on('curtainDown', (title) => {
      if (title === 'connectionError') {
        this.connectionError = true;
      }
      if (this.curtainDown && this.curtainSubtitle === 'connectionError' && title === 'connectionError') {
        return;
      }
      this.toggleText(false, 0);
      this.handlingCurtain(true, 2000, title);
      setTimeout(() => {
        this.$store.commit('SET_FETCH_EVENTS_ERROR', {
          key: 'Draw',
          value: false,
        });
      }, 3000);
    });
    EventBus.$on('curtainUp', () => {
      if (this.$store.getters.fetchEventsError) {
        return;
      }
      this.toggleText(false, 0);
      this.toggleCurtainSubtitle(false);
      this.upCurtain(1200, 3000);
    });
    EventBus.$on('changeTitle', (title) => {
      this.curtainSubtitle = title;
    });
    EventBus.$on('disabledActiveCounter', (status) => {
      this.disabledActiveCounter = status;
    });
  },
  watch: {
    drawInProgress(newStatus) {
      const delay = newStatus ? 3000 : 0;
      setTimeout(() => {
        this.drawIdActive = newStatus;
      }, delay);
    },
  },
};
</script>

<style lang="scss" scoped>
.retail-header {
  left: 0;
  height: 50px;
  position: absolute;
  top: 0;
  transition: height 1s ease-in-out;
  width: 100%;
  z-index: 2;
  @media (min-width: 1920px) {
    height: 80px;
  }
  &.down {
    height: 100vh;
    .header-curtain {
      background: #1b1c1f;
      .curtain-info {
        flex-direction: column;
        position: relative;
        transform: scale(3.3);
        h3 {
          left: 50%;
          font-size: 12px;
          position: absolute;
          top: 40px;
          transform: translateX(-50%);
          @media (min-width: 1920px) {
            top: 65px;
          }
          &.active:not(&.active-subtitle) {
            opacity: .5;
          }
          &.active-subtitle {
            color: #fff;
          }
        }
      }
    }
  }
  .header-curtain {
    align-items: center;
    background: rgba(29, 33, 36, 0.9);
    display: flex;
    height: calc(100% - 5px);
    justify-content: center;
    transition: background 1s linear;
    .curtain-info {
      align-items: center;
      display: flex;
      position: relative;
      transition: all 1s linear;
      transform: scale(1, 1);
      .lottery-icon {
        opacity: 0;
        transition: opacity .5s linear;
        &.active {
          opacity: 1;
        }
      }
      h3 {
        color: rgba(255, 255, 255, 0.5);
        font-family: 'Nunito', sans-serif;
        font-size: 24px;
        position: absolute;
        text-transform: uppercase;
        transition: all 1s linear;
        opacity: 0;
        white-space: nowrap;
        &.active {
          opacity: 1;
        }
      }
    }
    .header-next-draw-time {
      align-items: center;
      display: flex;
      justify-content: space-between;
      opacity: 0;
      position: absolute;
      right: 16px;
      text-transform: uppercase;
      transition: opacity 1s linear;
      &.active {
        opacity: 1;
      }
      &.draw-id {
        .info-titles {
          margin-right: 8px;
        }
        .info-time {
          font-size: 36px;
        }
      }
      .info-titles {
        display: flex;
        flex-direction: column;
        font-size: 14px;
      }
      .info-time {
        max-width: 180px;
        margin-right: -6px;
      }
    }
  }
  .header-loader {
    background: rgba(255, 255, 255, 0.2);
    height: 5px;
  }
  .subtitle {
    bottom: 50px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 40px;
    font-weight: 100;
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    transition: opacity 1s linear;
    opacity: 0;
    text-align: center;
    text-transform: uppercase;
    &.active {
      opacity: .3;
    }
  }
}
</style>
