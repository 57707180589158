<template>
  <span class="remaining-time"
       :class="{'out-header': !inHeader}">
    {{ remainingTime }}
  </span>
</template>

<script>
import EventBus from '@/store/EventBus';

export default {
  name: 'RemainingTime',
  data() {
    return {
      remainingTime: '',
    };
  },
  props: {
    inHeader: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    EventBus.$on('remainingTime', (time) => {
      this.remainingTime = time;
    });
  },
};
</script>

<style lang="scss" scoped>
.remaining-time {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 36px;
  padding: 0 8px;
  &.out-header {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    max-width: 180px;
    @media (min-width: 1920px) {
      font-size: 24px;
      width: 100px;
    }
  }
}
</style>
